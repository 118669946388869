import "bootstrap";
import $ from "jquery";

function smoothScrollingTo(target) {
    $('html,body').animate({ scrollTop: $(target).offset().top }, 1000);
}

$(document).ready(function () {
    if (location.hash)
        smoothScrollingTo(location.hash);   

    $('#news-arrow').on('click', function (event) {        
        event.preventDefault();                
        smoothScrollingTo(this.hash);
    });
});